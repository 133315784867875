import React from 'react';
import { useEffect, useState } from 'react';
import { injectIntl, useIntl } from "react-intl";
import { connect } from "react-redux";
import {
    ELUS_QUERY,
    ELUS_GET_MEMBERS,
    ELUS_GET_SEC_TYPES,
    ELUS_GET_ACCOUNTS,
    ELUS_GET_ISINS, ELUS_GET_DOCUMENT,
    GET_CONFIG_PARAM
} from "../../constants/uri";
import { checkHttpStatus, get, post } from "../../utils/RestUtilsWithAxios";
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Typography from "@material-ui/core/Typography";
import Button from '@material-ui/core/Button';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { makeStyles } from "@material-ui/core/styles/index";
import MaterialUIDatePicker from '../../components/EyatUIDatePicker';
import * as DownloadUtils from '../../utils/downloadUtils';
import * as Globals from '../../constants/globals';
import {alertBox} from "../../components/BoxProvider";
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import TablePagination from '@material-ui/core/TablePagination';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import NumberFormat from 'react-number-format';
import PropTypes from 'prop-types';
import { Formik } from "formik";
import Divider from "@material-ui/core/Divider";
import Dropdown from '../../components/Dropdown';
import TextField from '@material-ui/core/TextField';
import Download from '@material-ui/icons/ArrowDropDown';
import MaterialTooltip from "@material-ui/core/Tooltip";
import format from "date-fns/format";
import clsx from 'clsx';
import * as Regex from "../../constants/Regexp";
import InfoIcon from "@material-ui/icons/InfoOutlined";

/**
 * desc
 * @param a
 * @param b
 * @param orderBy
 * @return {number}
 */
function desc(a, b, orderBy) {
    if(orderBy !== 'processDate'){
        if (orderBy === 'isin' || orderBy === 'secType' || orderBy === 'docName' || orderBy === 'amount') {
            if (b[orderBy] < a[orderBy]) {
                return -1;
            }
            if (b[orderBy] > a[orderBy]) {
                return 1;
            }
            return 0;
        } else
            return b[orderBy].toString().localeCompare(a[orderBy].toString());
    }
    else {
        if(a[orderBy] == null && b[orderBy] != null)
            return 1;
        else if(a[orderBy] != null && b[orderBy] == null)
            return -1;
        else if (a[orderBy] == null && b[orderBy] == null)
            return 0;
        else{
            const dateIndices = [ 6, 7, 8, 9, 3, 4, 0, 1 ];
            var r = 0;
            for(var i=0; i<dateIndices.length; i++){
                r = a[orderBy].charCodeAt(dateIndices[i]) - b[orderBy].charCodeAt(dateIndices[i]);
                if(r !== 0)
                    return r;
            }
            return r;
        }
    }
}

/**
 * stableSort
 * @param array
 * @param cmp
 */
function stableSort(array, cmp) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = cmp(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    return stabilizedThis.map(el => el[0]);
}

/**
 * getSorting
 * @param order
 * @param orderBy
 * @return {function(*=, *=): number}
 */
function getSorting(order, orderBy) {
    return order === 'desc' ? (a, b) => desc(a, b, orderBy) : (a, b) => -desc(a, b, orderBy);
}

const headStyles = makeStyles(theme => ({
    headFont : {
        fontSize: 13,
        fontWeight : 600
    }
}));

/**
 * EnhancedTableHead
 * @param props
 * @return {*}
 * @constructor
 */
function EnhancedTableHead(props) {
    const { order, orderBy, onRequestSort } = props;
    const createSortHandler = property => event => {
        onRequestSort(event, property);
    };
    const headClasses = headStyles();

    const headRows = [
        { id: 'processDate', numeric: false, disablePadding: false, label: props.intl.
            formatMessage({id:"ELUS.TABLE.OP_DATE"})},
        { id: 'member', numeric: false, disablePadding: false, label: props.intl.
            formatMessage({id:"ELUS.TABLE.MEMBER"}) , style:{minWidth:200}},
        { id: 'accNumber', numeric: false, disablePadding: false, label: props.intl.
            formatMessage({id:"ELUS.TABLE.ACC_NUMBER"}) },
        { id: 'isin', numeric: false, disablePadding: false, label: props.intl.
            formatMessage({id:"ELUS.TABLE.ISIN"}) },
        { id: 'processType', numeric: false, disablePadding: false, label: props.intl.
            formatMessage({id:"ELUS.TABLE.OP_TYPE"}) },
        { id: 'secType', numeric: false, disablePadding: false, label: props.intl.
            formatMessage({id:"ELUS.TABLE.SEC_TYPE"}) },
        { id: 'amount', numeric: false, disablePadding: false, label: props.intl.
            formatMessage({id:"ELUS.TABLE.AMOUNT"}) },
        { id: 'docNo', numeric: false, disablePadding: false, label: props.intl.
            formatMessage({id:"ELUS.TABLE.FILE_NO"}) },
        { id: 'docName', numeric: false, disablePadding: false, label: props.intl.
            formatMessage({id:"ELUS.TABLE.FILE_NAME"}) },
    ];

    return (
        <TableHead>
            <TableRow>
                {headRows.map(row => (
                    <TableCell
                        key={row.id}
                        style={row.style ? row.style : null}
                        align={row.numeric ? 'right' : 'left'}
                        padding={row.disablePadding ? 'none' : 'default'}
                        sortDirection={orderBy === row.id ? order : false}
                        className={headClasses.headFont}
                    >
                        <TableSortLabel
                            active={orderBy === row.id}
                            direction={order}
                            onClick={createSortHandler(row.id)}
                        >
                            {row.label}
                        </TableSortLabel>
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}
EnhancedTableHead.propTypes = {
    onRequestSort: PropTypes.func.isRequired,
    order: PropTypes.string.isRequired,
    orderBy: PropTypes.string.isRequired,
};

const tableStyles = makeStyles(theme => ({
    table: {
        minWidth: 750,
    },
    tableWrapper: {
        overflowX: 'auto',
    },
    fontSize13:{
        fontSize: 13
    },
    panelHeader: {
        backgroundColor: Globals.COLOR_LIGHT_GRAY,
        color: 'white',
        borderRadius: 4,
        marginBottom: 16,
        minHeight: 36
    },
    panelIcon: {
        color: 'white'
    },
    panelText: {
        margin: "6px 0"
    },
    panelContent: {
        flexDirection: "column"
    }
}));

/**
 * IntermediaryTable
 * @param props
 * @return {*}
 * @constructor
 */
function IntermediaryTable (props) {
    const classes = tableStyles();
    const [order, setOrder] = React.useState('asc');
    const [orderBy, setOrderBy] = React.useState('processDate');
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(25);
    const rows = props.data.rows;

    /**
     * handleRequestSort
     * @param event
     * @param property
     */
    function handleRequestSort(event, property) {
        const isDesc = orderBy === property && order === 'desc';
        setOrder(isDesc ? 'asc' : 'desc');
        setOrderBy(property);
    }

    /**
     * handleChangePage
     * @param event
     * @param newPage
     */
    function handleChangePage(event, newPage) {
        setPage(newPage);
    }

    /**
     * handleChangeRowsPerPage
     * @param event
     */
    function handleChangeRowsPerPage(event) {
        setRowsPerPage(+event.target.value);
        setPage(0);
    }

    /**
     * formatDate
     * @param dateStr
     */
    function formatDate(date) {
        var formattedDate = date.substring(6) + "." + date.substring(4,6) + "." + date.substring(0,4);
        return formattedDate;
    }

    function downloadDocument(row) {

        var postData = {
        };


                postData = row;


        post(ELUS_GET_DOCUMENT, postData)
            .then(function (response) {
                DownloadUtils.downloadFileByContent(response.data, postData.docName +".pdf", Globals.CONTENT_TYPE_PDF);
            })
            .catch(err => {
                checkHttpStatus(err, props.intl);
                console.error(err);
            });
    }

    return (
    <div>
        <ExpansionPanel>
            <ExpansionPanelSummary classes={{
                root: classes.panelHeader, content: classes.panelText,
                expandIcon: classes.panelIcon
            }}
                                   expandIcon={<ExpandMoreIcon/>}
            >
                <Typography variant="h6">{formatDate(props.data.id.toString())} - Belge
                    Sayısı: {rows.length}</Typography>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails classes={{root: classes.panelContent}}>
                <div className={classes.tableWrapper}>
                    <Table
                        className={classes.table}
                        aria-labelledby="tableTitle"
                        size={'medium'}
                    >
                        <EnhancedTableHead
                            order={order}
                            orderBy={orderBy}
                            onRequestSort={handleRequestSort}
                            rowisin={rows.length}
                            intl={props.intl}
                        />
                        <TableBody>
                            {
                                stableSort(rows, getSorting(order, orderBy))
                                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                    .map((row, index) => {
                                        return (
                                            <TableRow
                                                hover
                                                tabIndex={-1}
                                                key={row.name + index}
                                            >
                                                <TableCell component="th" key={'processDate_' + index}
                                                           id={'processDate_' + index} scope="row"
                                                           className={classes.fontSize13}> {row.processDate}
                                                </TableCell>
                                                <TableCell component="th" key={'member_' + index}
                                                           id={'member_' + index} scope="row"
                                                           className={classes.fontSize13}> {row.member}
                                                </TableCell>
                                                <TableCell component="th" key={'accNumber_' + index}
                                                           id={'accNumber_' + index} scope="row"
                                                           className={classes.fontSize13}> {row.accNumber}
                                                </TableCell>
                                                <TableCell component="th" key={'isin_' + index}
                                                           id={'isin_' + index} scope="row"
                                                           className={classes.fontSize13}> {row.isin}
                                                </TableCell>
                                                <TableCell component="th" key={'islemTipi_' + index}
                                                           id={'islemTipi_' + index} scope="row"
                                                           className={classes.fontSize13}> {row.processType}
                                                </TableCell>
                                                <TableCell component="th" key={'secType_' + index}
                                                           id={'secType_' + index} scope="row"
                                                           className={classes.fontSize13}> {row.secType}
                                                </TableCell>
                                                <TableCell component="th"  key={'amount_' + index}
                                                           id={'amount_' + index} scope="row"
                                                           className={classes.fontSize13}>  {row.amount}
                                                </TableCell>
                                                <TableCell component="th"  key={'docNo_' + index}
                                                           id={'docNo_' + index}
                                                           className={classes.fontSize13}>  {row.docNo}
                                                </TableCell>
                                                <TableCell component="th" align="right" key={'docName_' + index}
                                                           id={'docName_' + index}>
                                                    <MaterialTooltip title="Belge indir">
                                                    <a focusable="true" onClick={() => downloadDocument(row)}><Download size="large"
                                                                                                         className={classes.glowOnHover}/> {row.docName}
                                                    </a>
                                                    </MaterialTooltip>
                                                </TableCell>

                                            </TableRow>
                                        );
                                    })
                            }
                        </TableBody>
                    </Table>
                </div>
                <TablePagination
                    rowsPerPageOptions={[5, 10, 25]}
                    component="div"
                    count={rows.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    backIconButtonText={props.intl.formatMessage({id: "TABLE.PAGINATION.PREVIOUS"})}
                    nextIconButtonText={props.intl.formatMessage({id: "TABLE.PAGINATION.NEXT"})}
                    labelRowsPerPage={props.intl.formatMessage({id: "TABLE.PAGINATION.PER_PAGE"})}
                    onChangePage={handleChangePage}
                    onChangeRowsPerPage={handleChangeRowsPerPage}
                />
            </ExpansionPanelDetails>
        </ExpansionPanel>
    </div>
    )
}

const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
    },
    paper: {
        marginBottom: theme.spacing(2),
        margin: 'auto',
        padding: 30,
        paddingTop: 20
    },
    infoMsg: {
        marginBottom: theme.spacing(2),
        margin: 'auto',
        padding: 20
    },
    tableWrapper: {
        marginTop: theme.spacing(3),
        marginBottom: theme.spacing(3)
    },
    overflowX: {
        overflowX: 'auto',
    },
    showButton: {
        marginBottom: theme.spacing(3),
        [theme.breakpoints.up('sm')]: {
            width: 200
        },
    },
}));

/**
 * ELUSBelgeleri
 * @param props
 * @return {*}
 * @constructor
 */
function ELUSBelgeleri(props) {
    const [trText, setTrText] = React.useState('');
    const classes = useStyles();
    const [debtInstrumentInfos, setDebtInstrumentInfos] = useState([]);
    const [fade,setFade] = useState(false);
    const [selectedBeginDate, setSelectedBeginDate] = useState(beginInit());
    const [selectedEndDate, setSelectedEndDate] = useState(endInit());
    const [dataFieldMessage, setDataFieldMessage] = useState('');
    const [tableItems, setTableItems] = useState([]);
    const [loading, setLoading] = useState(false);
    const [transferData, setTransferData] = useState({});
    const [saveControl, setSaveControl] = React.useState(false);
    const [memberList, setMemberList] = useState([]);
    const [accountList, setAccountList] = useState([]);
    const [secTypeList, setSecTypeList] = useState([]);
    const [isinList, setIsinList] = useState([]);
    const islemList = islemTipiList();

    const [values, setValues] = useState({
        member: '',
        secType: '',
        docNoStart: '',
        docNoEnd: '',
        islemTipi: 'T',
        isin: '',
    });

    function islemTipiList() {

        var arr = [];
        var tm = {value:'T',label:'Tümü'};
        var al = {value:'A',label:'Alım'};
        var st = {value:'S',label:'Satım'};
        arr.push(tm);
        arr.push(al);
        arr.push(st);
        return arr;
    }

    useEffect(() => {
        let dateParam = {
            beginDate: selectedBeginDate,
            endDate: selectedEndDate
        };
        post(ELUS_GET_MEMBERS, dateParam)
           .then(function (response) {
               var members = [];
               members.push({value:'',label:'Seçim Yapınız'});
               for (var i = 0; i < response.data.length; i++) {
                   var newItem = {};
                   newItem.value = response.data[i].memberId;
                   newItem.label = response.data[i].mbrTitle;
                   members.push(newItem);
               }
               setMemberList(members);
               setFade(true);
           })
           .catch(function (error) {
               checkHttpStatus(error, props.intl);
           })

    }, [selectedBeginDate, selectedEndDate]);


    useEffect(() => {
        post(GET_CONFIG_PARAM, {name: "EYAT_ELUS_END_DATE"})
            .then(function (response) {

                setTrText(response.data.toString());
                if(response.data !=undefined && response.data!=null ) {
                setSelectedEndDate(trDate( response.data.toString().substring(4,6) + "/" + response.data.toString().substring(6)  + "/" + response.data.toString().substring(0,4)));

                }
                if(response.data !=undefined && response.data!=null ) {
                    setSelectedBeginDate(trBeginDate( response.data.toString().substring(4,6) + "/" + response.data.toString().substring(6)  + "/" + response.data.toString().substring(0,4)));

                }


            })
            .catch(function (error) {
                checkHttpStatus(error, intl);
            });



    }, []);

    useEffect(() => {
        let dateParam = {
            beginDate: selectedBeginDate,
            endDate: selectedEndDate
        };
        post(ELUS_GET_ACCOUNTS, dateParam)
            .then(function (response) {
                var accounts = [];
                accounts.push({value:'',label:'Seçim Yapınız'});
                for (var i = 0; i < response.data.length; i++) {
                    var newItem = {};
                    newItem.value = response.data[i].mbrAccNo;
                    newItem.label = response.data[i].mbrAccNo;
                    accounts.push(newItem);
                }
                setAccountList(accounts);
                setFade(true);
            })
            .catch(function (error) {
                checkHttpStatus(error, props.intl);
            })

    }, [selectedBeginDate, selectedEndDate]);

    useEffect(() => {
        get(ELUS_GET_SEC_TYPES)
            .then(function (response) {
                var secTypes = [];
                for (var i = 0; i < response.data.length; i++) {
                    var newItem = {};
                    newItem.value = response.data[i].secType;
                    newItem.label = response.data[i].secType;
                    secTypes.push(newItem);
                }
                setSecTypeList(secTypes);
                setFade(true);
            })
            .catch(function (error) {
                checkHttpStatus(error, props.intl);
            })

    }, []);

    useEffect(() => {
        let dateParam = {
            beginDate: selectedBeginDate,
            endDate: selectedEndDate
        };

        post(ELUS_GET_ISINS, dateParam)
            .then(function (response) {
                var isins = [];
                isins.push({value:'',label:'Seçim Yapınız'});
                for (var i = 0; i < response.data.length; i++) {
                    var newItem = {};
                    newItem.value = response.data[i];
                    newItem.label = response.data[i];
                    isins.push(newItem);
                }
                setIsinList(isins);
                setFade(true);
            })
            .catch(function (error) {
                checkHttpStatus(error, props.intl);
            })

    }, [selectedBeginDate, selectedEndDate]);

    React.useEffect(()=>{
        if (values.docNoEnd !== "" && (values.docNoEnd === values.docNoStart)) {
            setSelectedBeginDate(MAXbeginInit());
            setSelectedEndDate(endInit())

        }
    },[values.docNoEnd, values.docNoStart]);

    /**
     * formikRef
     * @param node
     */
    const formikRef = (node) => {
        if (node !== null) {
            setValues(node.values);
        }
    }

    /**
     * enableLoading - setLoading(true)
     */
    const enableLoading = () => {
        setLoading(true);
    };

    /**
     * disableLoading - setLoading(false)
     */
    const disableLoading = () => {
        setLoading(false);
    };


    /**
     * beginInit
     * @return {string}
     */
    function beginInit() {

        var date = new Date();
        date.setDate(date.getDate() - 32);

        return date.getFullYear().toString() + (formatMonthOrDay(date.getMonth() + 1)).toString() +
            (formatMonthOrDay(date.getDate())).toString();
    }

    /**
     * beginInit
     * @return {string}
     */
    function MAXbeginInit() {
        var date = new Date();
        date.setDate(date.getDate() - 730);
        return date.getFullYear().toString() + (formatMonthOrDay(date.getMonth() + 1)).toString() +
            (formatMonthOrDay(date.getDate())).toString();
    }

    /**
     * endInit
     * @return {string}
     */
    function endInit() {
        var date = new Date();
        date.setDate(date.getDate() - 2);
        return date.getFullYear().toString() + (formatMonthOrDay(date.getMonth() + 1)).toString() +
            (formatMonthOrDay(date.getDate())).toString();
    }

    function trDate(date2) {
        var date = new Date(date2);
        date.setDate(date.getDate());

        return date.getFullYear().toString()+ formatMonthOrDay(date.getMonth()+1).toString() + formatMonthOrDay(date.getDate()).toString()  ;

    }

    function trBeginDate(date2) {
        var date = new Date(date2);
        date.setDate(date.getDate()-30);

        return date.getFullYear().toString()+ formatMonthOrDay(date.getMonth()+1).toString() + formatMonthOrDay(date.getDate()).toString()  ;

    }




    /**
     * formatMonthOrDay
     * @param m
     * @return {*}
     */
    function formatMonthOrDay(m) {
        if (m < 10)
            return '0' + m;
        else
            return m;
    }


    const intl = useIntl();

    const keys = Object.keys(tableItems);
    const mapValues = Object.values(tableItems);
    var newValues = [];
    var flag = false;

    return (
        <div className={classes.root}>
            <Paper className={classes.paper}>
                <Formik
                    enableReinitialize
                    validateOnBlur={false}
                    validateOnChange={false}
                    innerRef={formikRef}
                    initialValues={values}
                    validate={values => {

                        const errors = {};
                        if (values.docNoEnd < values.docNoStart) {
                            errors.docNoEnd = props.intl.formatMessage({id: "ELUS.VALIDATION.MORE_OR_EQUAL"});
                            errors.docNoStart = props.intl.formatMessage({id: "ELUS.VALIDATION.LESS_OR_EQUAL"});
                        }

                        if (values.docNoEnd < values.docNoStart) {
                            errors.docNoEnd = props.intl.formatMessage({id: "ELUS.VALIDATION.MORE_OR_EQUAL"});
                            errors.docNoStart = props.intl.formatMessage({id: "ELUS.VALIDATION.LESS_OR_EQUAL"});
                        }

                        if (!values.secType) {
                            errors.secType = props.intl.formatMessage({id: "AUTH.VALIDATION.REQUIRED_FIELD"});
                        }

                        if (Object.keys(errors).length !== 0) {
                            alertBox("", "Hatalı / eksik bilgi girişi yaptınız. Lütfen girdiğiniz alanları " +
                                "kontrol ediniz!", Globals.ALERT_WARNING, props.intl);
                        }

                        return errors;
                    }}
                    onSubmit={(values, {setStatus, setSubmitting}) => {
                        enableLoading();
                        var endDate = new Date(selectedEndDate.substring(0, 4) + '-' + selectedEndDate.substring(4, 6) + '-' + selectedEndDate.substring(6, 8));
                        var beginDate = new Date(selectedBeginDate.substring(0, 4) + '-' + selectedBeginDate.substring(4, 6) + '-' + selectedBeginDate.substring(6, 8));
                        var compareDate = new Date(selectedBeginDate.substring(0, 4) + '-' + selectedBeginDate.substring(4, 6) + '-' + selectedBeginDate.substring(6, 8));
                        var date = new Date();
                        date.setDate(date.getDate()-2);

                        if (beginDate.getTime() > endDate.getTime()) {
                            disableLoading();
                            alertBox("", 'Başlangıç Tarihi Bitiş Tarihinden büyük olamaz.', Globals.ALERT_ERROR, intl);
                            return;
                        }
                        compareDate.setDate(compareDate.getDate() + 30);

                        if (values.docNoEnd === "" || values.docNoStart === "" ||
                            (values.docNoEnd !== values.docNoStart)) {
                            if (compareDate.getTime() < endDate.getTime()) {
                                disableLoading();
                                alertBox("", 'Seçtiğiniz tarih aralığı 30 günden büyük olamaz', Globals.ALERT_ERROR, intl);
                                return;
                            }
                        }
                        var yearAgoTime = new Date().setFullYear(new Date().getFullYear()-2);
                        if(beginDate.getTime() - yearAgoTime < 0){
                            disableLoading();
                            alertBox("", 'Geçmişe dönük 2 yıllık sorgu yapılabilir.', Globals.ALERT_ERROR, intl);
                            return;
                        }

                        if (endDate.getTime() > date.getTime()) {
                            alertBox("", "Elüs alım satım işlemlerine ait belgeler sisteme işlem tarihinden 2 gün sonra yüklenmektedir. Son 2 güne ait işlemleri işlem tarihinden 2 gün sonra sorgulayabilirsiniz.",
                                Globals.ALERT_WARNING, props.intl);
                        }

                        let postData = {
                            beginDate: selectedBeginDate,
                            endDate :  selectedEndDate,
                            mbrId : values.member,
                            mbrAccNo: values.mbrAccNo,
                            secType : values.secType,
                            isin :  values.isin,
                            processType : values.islemTipi,
                            docNoStart :  values.docNoStart,
                            docNoEnd :  values.docNoEnd
                        }

                        post(ELUS_QUERY, postData)
                            .then(function (response) {
                                if (Object.keys(response.data.elusDetailMap).length === 0) {
                                    disableLoading();
                                    setDataFieldMessage("Sorgu sonucu belge bulunamadı.");
                                    setTableItems([]);
                                } else {
                                    disableLoading();
                                    setDataFieldMessage("");
                                    setTableItems(response.data.elusDetailMap);
                                }
                            })
                            .catch(function (error) {
                                disableLoading();
                                checkHttpStatus(error, props.intl);
                            })

                        setTransferData(values);
                        setSaveControl(true);
                        setSubmitting(false);

                    }}
                >
                    {({
                          values,
                          status,
                          errors,
                          touched,
                          handleChange,
                          handleBlur,
                          handleSubmit,
                          //handleReset,
                          isSubmitting
                      }) => (
                        <form onSubmit={handleSubmit} autoComplete="off">

                            {status && (
                                <div role="alert" className="alert alert-danger">
                                    <div className="alert-text">{status}</div>
                                </div>
                            )}

                            <Grid container spacing={5}>
                            <Grid item xs={12} sm={6} container direction="column" justify="flex-end">
                                <Typography classes={{h6:classes.titleGutter}}  variant="h6">İşlem Tarih Aralığı(*)</Typography>
                                <Grid container spacing={2} style={{ marginBottom: 16 }}>
                                    <Grid item xs={6}>
                                        <MaterialUIDatePicker
                                            containerId="mui-pickers-date"
                                            label="Başlangıç Tarihi"
                                            disableAfter={selectedEndDate}
                                            maxDateMessage="İşlem Bitiş Tarihinden İleri Tarih Seçilemez"
                                            setContainerSelectedDate={setSelectedBeginDate}
                                            dateString={selectedBeginDate} 
                                            notShowToday={true}
                                        />
                                    </Grid>
                                    {trText!=undefined && trText!='' &&
                                    <Grid item xs={6}>
                                        <MaterialUIDatePicker
                                            containerId="mui-pickers-date2"
                                            label="Bitiş Tarihi"
                                            maxDateMessage={trText.substring(6) + "/" + trText.substring(4,6) + "/" + trText.substring(0,4) + " Tarihinden İleri Tarih Seçilemez"}
                                            disableAfter={trDate( trText.substring(4,6) + "/" + trText.substring(6)  + "/" + trText.substring(0,4))}
                                            setContainerSelectedDate={setSelectedEndDate}
                                            dateString={selectedEndDate} 
                                            notShowToday={true}
                                        />
                                    </Grid>}

                                    {trText==undefined || trText=='' &&
                                        <Grid item xs={6}>
                                            <MaterialUIDatePicker
                                                containerId="mui-pickers-date2"
                                                label="Bitiş Tarihi"
                                                setContainerSelectedDate={setSelectedEndDate}
                                                dateString={selectedEndDate} />
                                        </Grid>}
                                </Grid>
                            </Grid>
                            <Grid item xs={12} sm={6} container direction="column" justify="flex-end">
                                <Typography classes={{h6:classes.titleGutter}}  variant="h6">Alım Satım Belge No Aralığı</Typography>
                                <Grid container spacing={2} style={{ marginBottom: 16, height: 76, marginTop: 10 }}>
                                    <Grid item xs={6}>
                                            <TextField
                                                fullWidth
                                                id="docNoStart"
                                                name="docNoStart"
                                                label="Belge No Başlangıç"
                                                variant="outlined"
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                value={values.docNoStart}
                                                helperText={touched.docNoStart && errors.docNoStart}
                                                error={Boolean(touched.docNoStart && errors.docNoStart)}
                                            />

                                    </Grid>
                                    <Grid item xs={6}>
                                            <TextField
                                                fullWidth
                                                id="docNoEnd"
                                                name="docNoEnd"
                                                label="Belge No Bitiş"
                                                variant="outlined"
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                value={values.docNoEnd}
                                                helperText={touched.docNoEnd && errors.docNoEnd}
                                                error={Boolean(touched.docNoEnd && errors.docNoEnd)}
                                            />
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12} sm={6} container direction="column" justify="flex-end">
                                    <Grid container spacing={2} style={{ marginBottom: 16 }}>
                                        <Grid item xs={6}>
                                            <Typography classes={{h6:classes.titleGutter}}  variant="h6">Üye</Typography>
                                            <Grid item style={{height: 76}}>
                                                <Dropdown name="member"
                                                          id="member"
                                                          title='Üye Seç'
                                                          label="0XXXXXXXXXX"
                                                          items={memberList}
                                                          onChange={handleChange}
                                                          error={Boolean(errors.member)}
                                                          helperText={errors.member}
                                                          containerSelectedItem={values.member}
                                                />
                                            </Grid>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <Typography classes={{h6:classes.titleGutter}}  variant="h6">Hesap</Typography>
                                            <Grid item style={{height: 70}}>
                                                <Dropdown   name="mbrAccNo"
                                                            id="mbrAccNo"
                                                            title='Hesap Seç'
                                                            label="0XXXXXXXXXX"
                                                            items={accountList}
                                                            onChange={handleChange}
                                                            error={Boolean(errors.mbrAccNo)}
                                                            helperText={errors.mbrAccNo}
                                                            containerSelectedItem={values.mbrAccNo}
                                                />
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12} sm={6} container direction="column" justify="flex-end">
                                    <Grid container spacing={2} style={{ marginBottom: 16 }}>
                                        <Grid item xs={4}>
                                            <Typography classes={{h6:classes.titleGutter}}  variant="h6">Ürün Türü(*)</Typography>
                                            <Grid item style={{height: 70}}>
                                                <Dropdown   name="secType"
                                                            id="secType"
                                                            title='Ürün Türü'
                                                            label="0XXXXXXXXXX"
                                                            items={secTypeList}
                                                            onChange={handleChange}
                                                            error={Boolean(errors.secType)}
                                                            helperText={errors.secType}
                                                            containerSelectedItem={values.secType}
                                                />
                                            </Grid>
                                        </Grid>
                                        <Grid item xs={4}>
                                            <Typography classes={{h6:classes.titleGutter}}  variant="h6">ISIN</Typography>
                                            <Grid item style={{height: 76}}>
                                                <Dropdown name="isin"
                                                          id="isin"
                                                          title='ISIN Seç'
                                                          label="ISIN"
                                                          items={isinList}
                                                          onChange={handleChange}
                                                          error={Boolean(errors.isin)}
                                                          helperText={errors.isin}
                                                          containerSelectedItem={values.isin}
                                                />
                                            </Grid>
                                        </Grid>
                                        <Grid item xs={4}>
                                            <Typography classes={{h6:classes.titleGutter}}  variant="h6">İşlem Tipi</Typography>
                                            <Grid item style={{height: 70}}>
                                                <Dropdown   name="islemTipi"
                                                            id="islemTipi"
                                                            title='İşlem Tipi'
                                                            label="islemTipi"
                                                            items={islemList}
                                                            onChange={handleChange}
                                                            error={Boolean(errors.islemTipi)}
                                                            helperText={errors.islemTipi}
                                                            containerSelectedItem={values.islemTipi}
                                                />
                                            </Grid>
                                        </Grid>

                                    </Grid>
                        </Grid>
                        </Grid>
                            <Divider className={classes.divider}/>
                            <div style={{textAlign:'end', paddingTop:16}}>
                                <Grid container justify="flex-end" spacing={3}>
                                  <Grid item className={classes.showButton}>
                                    <Button variant="contained" color='primary' size='large' fullWidth type='submit'
                                            className={[`btn ${clsx(
                                                {
                                                    "kt-spinner kt-spinner--right kt-spinner--md kt-spinner--light": loading
                                                }
                                            )}`]}>
                                        GÖSTER
                                    </Button>
                                 </Grid>
                                </Grid>
                            </div>
                        </form>
                    )}
                </Formik>

            </Paper>
            <Paper className={classes.paper}>
                {
                    mapValues.map((val) => {
                        if(newValues.length > 0) {
                            newValues.map((newVal, index) => {
                                if(val.date === newVal.id){
                                    newValues[index].rows.push(val);
                                    flag = true;
                                }
                            })
                        }
                        if(!flag){
                            newValues.push({id: val.date, rows: [val]})
                        }
                        flag = false;
                    })
                }
                {
                    keys.length > 0 ?
                    newValues.map(sec => (
                        <IntermediaryTable intl={props.intl} data={sec} />
                    ))
                    :
                        <Typography align='center' classes={{ h6: classes.textContainer }} variant='h6'>
                            {dataFieldMessage}
                        </Typography>
                }

            </Paper>
            {trText!=undefined && trText!='' &&
                <Paper className={classes.infoMsg}>
                    <Grid container style={{backgroundColor: "white"}}>
                        <InfoIcon size="small" style={{marginRight:8}} />
                        <Typography display="inline" variant="subtitle1">
                            Mevzuat değişikliği nedeniyle {trText.substring(6) + "/" + trText.substring(4,6) + "/" + trText.substring(0,4)} tarihinden itibaren ELÜS Alım/Satım Belgeleri MKK tarafından hazırlanmayacak olup bu ekrandan sadece belirtilen tarih öncesindeki ELÜS Alım Satım Belgelerinize ulaşabilirsiniz. ELÜS İşlem Sonuç Belgesi için hesabınızın bulunduğu Yatırım Kuruluşu veya Ürün Piyasası Aracı Kurumu ile iletişime geçebilirsiniz.
                        </Typography>
                    </Grid>
                </Paper> }
        </div>
    )
}

/**
 * injectIntl
 */
export default injectIntl(
    connect(
        null,
    )(ELUSBelgeleri)
);