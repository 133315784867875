import 'date-fns';
import trLocale from "date-fns/locale/tr";
import format from "date-fns/format";
import React from 'react';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import DateFnsUtils from '@date-io/date-fns';
import {
    MuiPickersUtilsProvider,
    DatePicker,
} from '@material-ui/pickers';
import { TextField } from '@material-ui/core';
/**
 *
 * @type {<Styles<Theme, {}, string>>}
 */
const useStyles = makeStyles({
    grid: {
        width: '100%',
    },
});

/**
 * Created by ebilgic on 3.11.2020
 */
class TrLocalizedUtils extends DateFnsUtils {
    /**
     *
     * @param date
     * @return {string}
     */
    getCalendarHeaderText(date) {
      return format(date, "LLLL", { locale: this.locale });
    }

    /**
     *
     * @param date
     * @return {string}
     */
    getDatePickerHeaderText(date) {
      return format(date, "dd MMMM", { locale: this.locale });
    }
  }

/**
 *
 * @param props
 * @return {*}
 * @constructor
 */
export default function EyatUIDatePicker(props) {
    // The first commit of Material-UI
    const [selectedDate, setSelectedDate] = React.useState(prepareDate(props.dateString));
    console.log(props.disableAfter)
    const classes = useStyles();

    /**
     *
     * @param dateStr
     * @return {Date}
     */
    function prepareDate(dateStr) {
        var date = dateStr != null ? new Date(dateStr.substring(0, 4) + '-' + dateStr.substring(4, 6) + '-' + dateStr.substring(6, 8))
            : null;

        return date;
    }

    /**
     *
     * @param m
     * @return {*}
     */
    function formatMonthOrDay(m) {
        if(m<10)
            return '0'+m;
        else
            return m;
    }

    /**
     *
     * @param date
     */
    function handleDateChange(date) {
        setSelectedDate(date);
        if(date==null){
            props.setContainerSelectedDate('');
        }else {
            props.setContainerSelectedDate(date.getFullYear().toString() + (formatMonthOrDay(date.getMonth() + 1)).toString() + (formatMonthOrDay(date.getDate())).toString());
        }
    }

    return (
        <MuiPickersUtilsProvider utils={TrLocalizedUtils} locale={trLocale}>
            <Grid container className={props.style ? props.style : classes.grid} justify="space-around">
                {props.maxDateMessage ? 
                <DatePicker
                    margin={props.noMargin ? undefined : "normal"}
                    fullWidth
                    disableFuture={props.disableFuture}
                    maxDate={props.disableAfter? prepareDate(props.disableAfter) : undefined}
                    maxDateMessage={props.maxDateMessage}
                    autoOk={true}
                    id={props.containerId}
                    name={props.containerId}
                    label={props.label ? props.label : "Tarih Seçiniz"}
                    invalidDateMessage="Hatalı Tarih Formatı"
                    format="dd MMMM yyyy"
                    cancelLabel = "kapat"
                    todayLabel = "bugün"
                    showTodayButton={props.notShowToday ? false : true}
                    okLabel = "tamam"
                    value={prepareDate(props.dateString)}
                    onChange={handleDateChange}
                    inputVariant="outlined"
                    disablePast={props.disablePast ? props.disablePast : false}
                    KeyboardButtonProps={{
                        'aria-label': 'change date',
                    }}
                    disabled={props.disabled}                    
                />
                :
                <DatePicker
                    margin={props.noMargin ? undefined : "normal"}
                    fullWidth
                    disableFuture={props.disableFuture}
                    maxDate={props.disableAfter? prepareDate(props.disableAfter) : undefined}
                    maxDateMessage={props.maxDateMessage}
                    autoOk={true}
                    id={props.containerId}
                    name={props.containerId}
                    label={props.label ? props.label : "Tarih Seçiniz"}
                    invalidDateMessage="Hatalı Tarih Formatı"
                    format="dd MMMM yyyy"
                    cancelLabel = "kapat"
                    todayLabel = "bugün"
                    showTodayButton
                    okLabel = "tamam"
                    value={prepareDate(props.dateString)}
                    onChange={handleDateChange}
                    inputVariant="outlined"
                    disablePast={props.disablePast ? props.disablePast : false}
                    KeyboardButtonProps={{
                        'aria-label': 'change date',
                    }}
                    disabled={props.disabled}
                    error = {props.error}
                    helperText={props.helperText}
                    
                />}
            </Grid>
        </MuiPickersUtilsProvider>
    );
}